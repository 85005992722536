import './index.scss';
import { Button, Input, Message, Form } from '@arco-design/web-react';
import { useNavigate } from '@modern-js/runtime/router';
import { ReactNode, useContext, useLayoutEffect, useRef, useState } from 'react';
import { validator } from '@utils/validator';
import dayjs from 'dayjs';
import useLocale from '@/hooks/useLocale';
import { fetchResetPassword } from '@/api/passport_api';
import useUrlParams from '@/hooks/useUrlParams';
import { encryptRSA } from '@/utils/rsa';
import SimpleHead from '@/components/simple-head';
import { ErrorCode } from '@/api/errno';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';
import { ModuleName, PageEvent, PageNameConfig, ResetPasswordEvent } from '@/config/track.config';
import { GlobalContext } from '@/routes/context';

const formItemLayout = {
  wrapperCol: {
    span: 24,
  },
};

const PasswordChange = () => {
  const { resetToken = '' } = useUrlParams();
  const locale = useLocale();
  const documentVisible = useVisibilityChange();
  const timeRef = useRef(dayjs().unix());
  const { collectEvent } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const newPassword = Form.useWatch('newPassword', form);
  const confirmPassword = Form.useWatch('confirmPassword', form);
  const navigate = useNavigate();

  const [confirmLogin, setConfirmLogin] = useState(false);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.?!@#$%^&*()_+\-=]).{8,}$/;

  const pageName = PageNameConfig.PWD_CHANGE;

  useLayoutEffect(() => {
    if (documentVisible) {
      timeRef.current = dayjs().unix();
      collectEvent?.([
        {
          event: PageEvent.PAGE_SHOW,
          params: {
            fromLink: 'email',
            pageName,
          },
        },
      ]);
    } else {
      onUnMounted();
    }
  }, [documentVisible]);

  const onUnMounted = () => {
    collectEvent?.([
      {
        event: PageEvent.PAGE_HIDE,
        params: {
          fromLink: 'email',
          pageName,
          duration: `${dayjs().unix() - timeRef.current}`,
        },
      },
    ]);
  };

  const checkConfirmPassword = (value: string | undefined, callback: (error?: ReactNode) => void) => {
    if (!value) {
      callback(locale.pwd_change_password_required);
      return;
    }

    if (newPassword !== value) {
      callback(locale.pwd_change_password_match_error);
    } else {
      callback();
    }
  };

  // 提交表单
  const onSubmit = async (values: any) => {
    setConfirmLogin(() => true);
    try {
      const { newPassword } = values;
      await fetchResetPassword({ reset_token: resetToken, new_password: encryptRSA(newPassword) || '' });
      Message.success(locale.pwd_change_password_success);
      collectEvent?.([
        {
          event: ResetPasswordEvent.CLICK_CONFIRM_CHANGE_PASSWORD_BUTTON,
          params: {
            pageName,
            status: 'success',
            redirectUrl: 'login',
            newPasswordLength: newPassword.length,
            moduleName: ModuleName.PWD_CHANGE_FORM,
          },
        },
      ]);
      navigate('/login', { replace: true });
    } catch (error: any) {
      const errorCode = error.code;
      collectEvent?.([
        {
          event: ResetPasswordEvent.CLICK_CONFIRM_CHANGE_PASSWORD_BUTTON,
          params: {
            pageName,
            errorCode,
            message: error.msg,
            status: 'fail',
            moduleName: ModuleName.PWD_CHANGE_FORM,
          },
        },
      ]);
      //  历史密码重复错误
      if (ErrorCode.H_ERR_USER_PASSWORD_DUPLICATION === errorCode) {
        Message.error(locale.pwd_change_duplication);
        return;
      }

      Message.error(`code: ${error.code}, msg:${error.msg}`);
    } finally {
      setConfirmLogin(() => false);
    }
  };

  return (
    <>
      <SimpleHead />
      <div className="page-body flex justify-center items-center  w-full">
        <div className="password-change flex flex-col justify-center items-center">
          <div className="password-change__title  text-center"> {locale.pwd_change_title}</div>
          <div className="line"></div>
          <Form {...formItemLayout} form={form} size="large" autoComplete="off" onSubmit={onSubmit} className="password-from">
            <Form.Item
              field="newPassword"
              rules={[
                {
                  required: true,
                  validator: validator.checkPassword,
                },
              ]}
            >
              <Input.Password placeholder={locale.pwd_change_input_new_pwd_placeholder} className="input-text" tabIndex={1} />
            </Form.Item>
            <Form.Item
              field="confirmPassword"
              rules={[
                {
                  required: true,
                  validator: checkConfirmPassword,
                },
              ]}
            >
              <Input.Password placeholder={locale.pwd_change_input_confirm_pwd_placeholder} className="input-text" tabIndex={2} />
            </Form.Item>
            <Form.Item>
              <Button
                long
                type="primary"
                htmlType="submit"
                className="submit-button"
                loading={confirmLogin}
                disabled={!(confirmPassword && newPassword) || confirmPassword !== newPassword || !passwordRegex.test(newPassword)}
              >
                {locale.pwd_change_input_submit}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
